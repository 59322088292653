import { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import Fuse from "fuse.js"
import { getGlobals } from "storyblok/api"
import StandardPageHeader from "components/DynamicComponent/molecules/PageHeader/StandardPageHeader"
import CallToAction from "components/CallToAction"

export default function Page() {
  const router = useRouter()
  const [results, setResults] = useState()

  useEffect(() => {
    const URL = /https?:\/\/.*(?=<\/loc>)/gim

    async function getSitemapURLs() {
      const origin = process.env.NEXT_PUBLIC_SITE_URL

      const index = await fetch("/sitemap.xml")
      const body = await index.text()
      const sitemapURLs = body.match(URL)

      const URLs = await Promise.all(
        sitemapURLs.map(async (sitemapURL) => {
          const sitemap = await fetch(sitemapURL.replace(origin, ""))
          const body = await sitemap.text()
          const URLs = body
            .match(URL)
            .map((URL) => URL.replace(origin, ""))
            .filter((URL) => URL !== "")

          return URLs
        }),
      )

      const fuse = new Fuse(URLs.flat(), {
        ignoreLocation: true,
        threshold: 0.4,
      })
      const results = fuse.search(router.asPath)
      setResults(results.slice(0, 5))
    }

    getSitemapURLs()
  }, [router.asPath])

  return (
    <main data-pagefind-ignore="all">
      <StandardPageHeader blok={{ heading: "404 Page Not Found" }} />

      <section className="bg-highlight-tint overflow-hidden px-5 py-8 lg:py-24">
        <div className="form-brackets form-brackets-highlight-tint relative max-w-screen-lg mx-auto flex flex-col items-center">
          <h2 className="text-center pb-12">
            Sorry, the page you are trying to access could not be found. It may have been deleted or moved.
          </h2>
          <div className="mx-auto">
            {results?.length ? (
              <>
                <h3 className="pb-8 text-center">Did you mean...</h3>
                <ul className="pb-12">
                  {results.map((result) => (
                    <li className="pb-6 last:pb-0 text-center" key={result.item}>
                      <Link
                        className="underline underline-offset-4 decoration-secondary hover:decoration-tertiary decoration-2"
                        href={result.item}
                      >
                        {result.item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </div>
          <CallToAction href="/">Back to Home</CallToAction>
        </div>
      </section>
    </main>
  )
}

export async function getStaticProps() {
  const globals = await getGlobals("exclude-global-sections")

  return {
    props: {
      globals,
    },
  }
}
